.errors-text {
    @apply text-sm italic text-red-600
}

.form-component {
    @apply p-2
}

.form-component label {
    @apply font-semibold
}

.form-component textarea,input {
    @apply bg-white rounded-lg border-2 border-gray-500 p-1 w-full
}

.submit-button {
    @apply bg-green-600 text-white p-2 rounded-lg m-2 hover:bg-green-400 border-green-800 border-2
}